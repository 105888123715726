import { Button } from '@popsure/dirty-swan';
import classNames from 'classnames';

import links from '../../constants';
import { spanishFormattedEuroCurrency } from '../../utils/spanishFormattedEuroCurrency';
import {
  whatsCoveredOwner,
  whatsCoveredTenant,
  whatsNotCovered,
} from './config';
import { CTABox } from './CTABox';
import { Header } from './Header';
import headerImage from './icons/household.png';
import { HouseholdQuote } from './models';
import { MoreQuestionsSection } from './MoreQuestions';
import styles from './style.module.scss';
import { WhatsCoveredSection } from './WhatsCoveredSection';
import { WhatsNotCoveredSection } from './WhatsNotCoveredSection';

type QuotePropTypes = {
  quote: HouseholdQuote;
};

export const HouseholdQuoteView = ({ quote }: QuotePropTypes) => {
  const { price, squareMeters, homeOwnership, paymentLink } = quote;

  return (
    <div className={classNames('bg-white', styles.container)}>
      <div className="d-flex fd-column ai-center jc-center bg-grey-200 py72">
        <Header
          title="Here is your quote"
          headerImage={{
            src: headerImage,
            alt: 'House chair with a guitar on it',
          }}
          priceHeader={{
            title: spanishFormattedEuroCurrency(price, true),
            subTitle: 'per month',
          }}
        />
        <div className="jc-center ai-center d-flex fd-column">
          <Button
            className="wmn4 mt32"
            onClick={() => {
              window.location.href = paymentLink;
            }}
          >
            Get covered
          </Button>
          <Button
            className="wmn4 mt8"
            onClick={() => {
              window.open(links.bookACall, '_blank');
            }}
            variant="filledWhite"
          >
            Book a call
          </Button>
        </div>
      </div>

      {quote?.homeOwnership === 'Owner' ? (
        <WhatsCoveredSection {...whatsCoveredOwner} />
      ) : (
        <WhatsCoveredSection {...whatsCoveredTenant} />
      )}

      <WhatsNotCoveredSection {...whatsNotCovered} />

      <CTABox
        header="Household insurance"
        price={spanishFormattedEuroCurrency(price, true)}
        subTitle="per month"
        onSubmit={() => {
          window.location.href = paymentLink;
        }}
        buttonText="Get covered"
        homeOwnership={homeOwnership}
        squareMeters={squareMeters}
      />

      <MoreQuestionsSection />
    </div>
  );
};

export { MoreQuestionsSection };
