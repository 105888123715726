import { IconCard } from '../IconCard';
import { NotCoveredSection } from '../models';
import { SectionHeader } from '../SectionHeader';
import styles from './style.module.scss';

export const WhatsNotCoveredSection = ({ title, items }: NotCoveredSection) => {
  return (
    <section className="p-container mt80 mb72">
      <div className={`d-flex fd-column ${styles.titleContainer}`}>
        <SectionHeader title={title} greyUnderline />
      </div>
      <div className="d-flex fd-row f-wrap jc-start c-gap8 r-gap24 mt32">
        {items.map(
          ({ title: itemTitle, icon, description: itemDescription }) => {
            return (
              <div className="wmx12" key={itemTitle}>
                <IconCard
                  title={itemTitle}
                  iconSrc={icon.src}
                  iconAlt={icon?.alt}
                  description={itemDescription}
                />
              </div>
            );
          }
        )}
      </div>
    </section>
  );
};
