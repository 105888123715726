import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';
import { fromZodError, ZodError } from 'zod-validation-error';

import { getPaymentLink } from '../../api/getPaymentLink';
import { Error } from '../Error';
import { LoadingSpinner } from '../LoadingSpinner';
import { TallyParams } from './models';

const ParamsSchema = z.object({
  productId: z.string(),
  monthlyPriceInCents: z.number(),
  email: z.string().optional(),
  redirectUrl: z.string().optional(),
  startDate: z.string().optional(),
});

const INTERVAL_TIME = 1500;
const TIMEOUT_TIME = 30000;
const REDIRECT_DOMAIN = 'https://buy.stripe.com/';

export const RedirectToPayment = () => {
  const [hasError, setHasError] = useState<boolean>(false);
  const validation = useRef<TallyParams | null>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsObject = Object.fromEntries(searchParams.entries());
  const { monthlyPrice, ...params } = paramsObject;

  useEffect(() => {
    try {
      const updatedTallyParams = {
        ...params,
        monthlyPriceInCents: Number(monthlyPrice) * 100,
      };

      validation.current = ParamsSchema.parse(updatedTallyParams);
      setHasError(false);
    } catch (err) {
      const validationError = fromZodError(err as ZodError);
      setHasError(true);
      /* eslint-disable no-console */
      console.error(`Failed validation: ${validationError}`);
    }
  }, [monthlyPrice, params]);

  useEffect(() => {
    if (hasError) return;

    let intervalId: NodeJS.Timer;

    const fetchAndRedirect = async () => {
      if (!validation.current) return;

      const response = await getPaymentLink(validation.current);

      if (
        response?.status === 200 &&
        response.data.url.startsWith(REDIRECT_DOMAIN)
      ) {
        clearInterval(intervalId);
        window.location.href = response.data.url;
      }
    };

    intervalId = setInterval(fetchAndRedirect, INTERVAL_TIME);

    const timer = setTimeout(() => {
      setHasError(true);
      /* eslint-disable no-console */
      console.error('Polling timeout exceeded.');
      clearInterval(intervalId);
    }, TIMEOUT_TIME);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timer);
    };
  }, [hasError, validation]);

  return hasError ? (
    <Error
      title="Something went wrong"
      description="Sorry, something didn’t work as it should. Starting over should address this issue."
      cta={{
        title: 'Contact us',
        onClick: () => {
          window.location.href = 'mailto: hello@feather-insurance.com';
        },
      }}
    />
  ) : (
    <LoadingSpinner
      title="Setting up your secure checkout"
      description="It may take a moment, so thank you for your patience."
    />
  );
};
