import axios from 'axios';

import { TallyParams } from '../components/RedirectToPayment/models';

const STAGING_FETCH_URL =
  'https://europe-west3-staging-popsure.cloudfunctions.net/payment-link-generator';
const PRODUCTION_FETCH_URL =
  'https://europe-west3-popsure-204813.cloudfunctions.net/payment-link-generator ';
const LOCAL_SERVER_URL = 'http://localhost:3000';
const STAGING_APP_URL = 'https://quote.staging.feather-insurance.com/';

export const getPaymentLink = async (params: TallyParams) => {
  const serializedParams = JSON.stringify(params);

  const currentUrl = window.location.href;

  const url =
    currentUrl.startsWith(LOCAL_SERVER_URL) ||
    currentUrl.startsWith(STAGING_APP_URL)
      ? STAGING_FETCH_URL
      : PRODUCTION_FETCH_URL;

  try {
    const response = await axios.post(url, serializedParams, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (error) {
    /* eslint-disable no-console */
    console.error(error);
  }
};
