import { images } from '@popsure/dirty-swan';

import briefCaseIcon from '../icons/briefcase.svg';
import buildingIcon from '../icons/building.svg';
import houseIcon from '../icons/house.svg';
import keysIcon from '../icons/keys.svg';
import lampIcon from '../icons/lamp.svg';
import documentIcon from '../icons/legal.svg';
import plugIcon from '../icons/plug.svg';
import shieldIcon from '../icons/shield.svg';
import toasterIcon from '../icons/toaster.svg';
import travelIcon from '../icons/travel.svg';
import pipeIcon from '../icons/water.svg';
import { CoveredSection, NotCoveredSection } from '../models';

export const whatsCoveredOwner: CoveredSection = {
  title: "What's covered?",
  documentLink:
    'https://docs.google.com/document/d/1kjwNbEPZ9Gc1dDQPpLboLQvuCxc7Fydo7VBn3HAfP8M/edit?usp=sharing',
  documentName: 'Policy information',
  items: [
    {
      title: 'Fire and smoke',
      description:
        'Our plans cover any material damage to your belongings caused by fire.',
      icon: { src: toasterIcon, alt: 'the illustration of a toaster' },
    },
    {
      title: 'Water damage',
      description:
        'If there is damage caused by leaks, breaks, or infiltrations from other houses in your belongings, we will compensate you for the damaged items.',
      icon: { src: pipeIcon, alt: 'the illustration of a broken pipe' },
    },
    {
      title: 'Keys and locks replacement',
      description:
        'If an accident happens and you need to change the locks of your home, we will cover the expenses incurred.',
      icon: { src: keysIcon, alt: 'the illustration of a pair of keys' },
    },
    {
      title: 'Emergency repairs',
      description:
        'There is a team ready to help at any time with all sorts of small problems. We will cover the first 3 hours of labor.',
      icon: { src: houseIcon, alt: 'the illustration of a house' },
    },
    {
      title: 'Glass breakage',
      description:
        'If a glass window, stovetop or mirror elements break, we will cover the costs of replacement.',
      icon: { src: buildingIcon, alt: 'the illustration of a building' },
    },
    {
      title: 'Civil liability, legal defense and dog liability',
      description:
        'If anybody is injured in your home or you cause damage in a neighbor’s home, the policy covers the damages.',
      icon: {
        src: documentIcon,
        alt: 'the illustration of a piece of paper with a tablet behind it',
      },
    },
    {
      title: 'Theft after break-in',
      description:
        'If someone breaks into your home and steals belongings, we will compensate you for the stolen items.',
      icon: {
        src: lampIcon,
        alt: 'the illustration of a piece of broken lamp',
      },
    },
    {
      title: 'Robbery (theft with violence)',
      description:
        'If you’re robbed outside of your home or there is a robbery in your garage or cellar, we will cover the cost of stolen properties.',
      icon: {
        src: images.moneyIncome,
        alt: 'the illustration of a stack of coins',
      },
    },
  ],
};

export const whatsCoveredTenant: CoveredSection = {
  title: "What's covered?",
  documentLink:
    'https://docs.google.com/document/d/1kjwNbEPZ9Gc1dDQPpLboLQvuCxc7Fydo7VBn3HAfP8M/edit?usp=sharing',
  documentName: 'Policy information',
  items: [
    {
      title: 'Tenant protection',
      description:
        'Will cover the legal costs to claim against your landlord & will advance you the deposit in case they do not want to return it.',
      icon: { src: shieldIcon, alt: 'the illustration of a shield' },
    },
    ...whatsCoveredOwner.items,
  ],
};

export const whatsNotCovered: NotCoveredSection = {
  title: "What's not covered?",
  items: [
    {
      title: 'Intentional acts and negligence',
      description:
        'You won’t be covered if for example, you leave the stove on, which results in burning down your home.',
      icon: { src: plugIcon, alt: 'the illustration of a wall plug' },
    },
    {
      title: 'Simple theft',
      description:
        'For example pickpocketing or any other type of theft without using violence against you.',
      icon: {
        src: briefCaseIcon,
        alt: 'the illustration of a briefcase',
      },
    },
    {
      title: 'Cars and motorcycles',
      description:
        'Any damage caused by a motor vehicle that requires its own insurance won’t be covered by this policy',
      icon: { src: travelIcon, alt: 'the illustration of a visa card' },
    },
  ],
};
