import { Button } from '@popsure/dirty-swan';

import { spanishFormattedEuroCurrency } from '../../../utils/spanishFormattedEuroCurrency';
import { HouseholdQuoteType } from '../models';

export type CTAProps = {
  header: string;
  price: string;
  subTitle: string;
  buttonText: string;
  squareMeters: number;
  homeOwnership: HouseholdQuoteType;
};

export const CTABox = ({
  header,
  price,
  subTitle,
  buttonText,
  homeOwnership,
  onSubmit,
  squareMeters,
}: { onSubmit: () => void } & CTAProps) => {
  const belongingsCoverage = spanishFormattedEuroCurrency(
    squareMeters * 500,
    true
  );
  const rebuildingCoverage = spanishFormattedEuroCurrency(
    squareMeters * 2000,
    true
  );

  const civilLiability = spanishFormattedEuroCurrency(300000, true);

  const itemCoverageLimit = spanishFormattedEuroCurrency(2000, true);

  return (
    <div className="p-container bg-grey-200 d-flex fd-column jc-center ai-center c-gap16 r-gap4 py40 my80">
      <div className="p-h1">{header}</div>
      <div className="p-h1--xl p--serif tc-primary-500 mt8">{price}</div>
      <div className="p-p--small fw-normal tc-grey-500">{subTitle}</div>
      <div className="p-container bg-grey-100 d-flex fd-column jc-center ai-center c-gap16 r-gap24 mt40 px32 py24">
        <div className="p-h3 tc-grey-900">Insurance sums</div>
        <p className="p-p ta-center fw-normal tc-grey-500">
          Your belongings are covered up to {belongingsCoverage}.{' '}
          {homeOwnership === 'Owner' &&
            `Cost to
          rebuild your home is covered up to ${rebuildingCoverage}.`}{' '}
          Civil liability up to {civilLiability}. Items worth more than{' '}
          {itemCoverageLimit} are optional. If you want to add some items worth
          more than {itemCoverageLimit} or change the insurance sums, just{' '}
          <a className="p-a" href="mailto: hello@feather-insurance.com">
            send us a message
          </a>{' '}
          with what you would like to change and we will update your quote
          immediately.
        </p>
      </div>
      <Button className="wmn4 mt16" onClick={onSubmit}>
        {buttonText}
      </Button>
    </div>
  );
};
