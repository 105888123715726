import axios from 'axios';
import { z } from 'zod';

import { HouseholdQuote } from '../components/HouseholdQuoteEs/models';

const currentUrl = window.location.href;
const localServerUrl = 'http://localhost:3000';
const stagingUrl = 'https://quote.staging.feather-insurance.com/';

const url =
  currentUrl.startsWith(localServerUrl) || currentUrl.startsWith(stagingUrl)
    ? 'https://n8n.data.f14e.com/webhook/staging/household/quote'
    : 'https://n8n.data.f14e.com/webhook/household/quote';

const HouseHoldQuoteSchema = z.object({
  submissionId: z.string(),
  paymentLink: z.string(),
  price: z.number(),
  homeOwnership: z.union([z.literal('Tenant'), z.literal('Owner')]),
  squareMeters: z.number(),
});

export const getHouseholdQuote = async (
  id: string
): Promise<HouseholdQuote | null> => {
  try {
    const response = await axios.post(url, { submissionId: id });
    return HouseHoldQuoteSchema.parse(response.data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};
