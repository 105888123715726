import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getHouseholdQuote } from '../../api/getHouseholdQuote';
import links from '../../constants';
import { Error } from '../Error';
import { LoadingSpinner } from '../LoadingSpinner';
import { HouseholdQuoteView } from './HouseholdQuote.view';
import { HouseholdQuote as Quote } from './models';

const INTERVAL_TIME = 3000;
const TIMEOUT_TIME = 120000;

export const HouseholdQuote = () => {
  const { id } = useParams<{ id?: string }>();

  const [quote, setQuote] = useState<Quote | null>();
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    if (!id) {
      setError('No valid ID provided in the URL.');
      return;
    }

    let intervalId: NodeJS.Timer;

    const fetchAndSetQuote = async () => {
      try {
        const fetchedQuote = await getHouseholdQuote(id);
        if (fetchedQuote && fetchedQuote.paymentLink && fetchedQuote.price) {
          setQuote(fetchedQuote);
          setError(null);
          clearInterval(intervalId);
        }
      } catch (err) {
        setError('Failed to fetch quote data.');
        clearInterval(intervalId);
      }
    };

    if (!quote && !error) {
      intervalId = setInterval(fetchAndSetQuote, INTERVAL_TIME);
    }

    const timer = setTimeout(() => {
      if (!quote) {
        setError('Polling timeout exceeded.');
        clearInterval(intervalId);
      }
    }, TIMEOUT_TIME);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timer);
    };
  }, [error, id, quote]);

  return quote ? (
    <HouseholdQuoteView quote={quote} />
  ) : error ? (
    <Error
      title="Something went wrong"
      description="Sorry, something didn’t work as it should. Starting over should address this issue."
      cta={{
        title: 'Start over',
        onClick: () => {
          window.location.href = links.homepageEnEs;
        },
      }}
      secondCta={{
        title: 'Contact us',
        onClick: () => {
          window.location.href = 'mailto: hello@feather-insurance.com';
        },
      }}
    />
  ) : (
    <LoadingSpinner
      title="We're processing your quote"
      description="It may take a moment, so thank you for your patience."
    />
  );
};
