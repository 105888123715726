import links from '../../../constants';
import featherLogoIcon from './icons/feather-icon.svg';
import styles from './style.module.scss';

export const MoreQuestionsSection = () => {
  return (
    <section className={`${styles['section-bottom']}`}>
      <div className={`p-body ${styles['section-bottom-container']}`}>
        <img src={featherLogoIcon} alt="logo" className={styles['logo-icon']} />
        <h1 className="p--serif p-h1 ta-center mt24">
          Have more questions or want to update your quote?
        </h1>
        <p className="p-p ta-center wmx8 mt24">
          Reach out to us. And if you&apos;re still not sure Feather is the
          right option for you, read{' '}
          <a href={links.reviews} target="_blank" rel="noopener noreferrer">
            some of our 5-star reviews
          </a>
          . Over 50.000 already trust Feather.
        </p>
        <div className={`mt24 wmn3 ${styles['statement-links-container']}`}>
          <a
            className={`p-a fw-bold ${styles['policy-link']}`}
            href={links.bookACall}
            target="_blank"
            rel="noreferrer"
          >
            Book a call
          </a>

          <div className={styles.divider} />
          <a
            className={`p-a fw-bold ${styles['policy-link']}`}
            href="mailto: hello@feather-insurance.com"
          >
            Write an email
          </a>
        </div>
      </div>
    </section>
  );
};
