import { Spinner } from '@popsure/dirty-swan';
import classNames from 'classnames';

import styles from './style.module.scss';

type LoadingProps = {
  title: string;
  description: string;
};

export const LoadingSpinner = ({ title, description }: LoadingProps) => {
  return (
    <div
      className={classNames(
        styles.loadingSpinner,
        'd-flex ai-center jc-start fd-column'
      )}
    >
      <div>
        <Spinner size="m" />
      </div>
      <div className={`mt40 ta-center ${styles.textAppear} ${styles.title}`}>
        {title}
      </div>
      <div className={`p-p mt16 ta-center ${styles.textAppear}`}>
        {description}
      </div>
    </div>
  );
};
