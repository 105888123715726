import links from '../../constants';
import desktopLogo from './img/logo-desktop.svg';
import mobileLogo from './img/logo-mobile.svg';
import styles from './style.module.scss';

export const NavigationBar = () => {
  return (
    <>
      <div className={styles.desktop}>
        <nav className={styles.desktopContainer}>
          <div className={styles.desktopContent}>
            <a href={links.homepageEnEs} className={styles.desktopLogo}>
              <img src={desktopLogo} className="d-block" alt="Home" />
            </a>
          </div>
        </nav>
      </div>
      <div className={styles.mobile}>
        <nav className={styles.mobileContainer}>
          <div className={styles.mobileContent}>
            <a href={links.homepageEnEs} className={styles.logo}>
              <img src={mobileLogo} className="d-block" alt="Home" />
            </a>
          </div>
        </nav>
      </div>
    </>
  );
};
