import { Button } from '@popsure/dirty-swan';

import feathersSvg from './icons/feathers.svg';
import styles from './style.module.scss';

interface ErrorProps {
  title: string;
  description: string;
  cta?: {
    title: string;
    onClick: () => void;
  };
  secondCta?: {
    title: string;
    onClick: () => void;
  };
}

export const Error = ({ title, description, cta, secondCta }: ErrorProps) => (
  <div
    className={`p-body d-flex ai-center jc-center ${styles.container}`}
    data-testid="recommendation-error-page"
  >
    <div className="d-flex fd-column ai-center jc-center wmx6">
      <img className="mb32" src={feathersSvg} alt="feathers" />
      <h2 className="p-h2 tc-grey-900 mb8">{title}</h2>
      <p className="p-p tc-grey-600 mb40 ta-center">{description}</p>
      {cta && (
        <Button className="wmn3" onClick={cta.onClick}>
          {cta.title}
        </Button>
      )}
      {secondCta && (
        <Button
          className="wmn3 mt8"
          onClick={secondCta.onClick}
          variant="filledWhite"
        >
          {secondCta.title}
        </Button>
      )}
    </div>
  </div>
);
