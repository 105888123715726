import styles from './style.module.scss';

interface Props {
  title: string;
  className?: string;
  greyUnderline?: boolean;
}

export const SectionHeader = ({
  title,
  className,
  greyUnderline = false,
}: Props) => (
  <>
    <h2 className={`p-h2 p--serif ${className ?? ''}`}>{title}</h2>
    <div
      className={`ws2 ${
        !greyUnderline
          ? styles['header-underline']
          : styles['header-underline--grey']
      }`}
    />
  </>
);
