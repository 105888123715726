import { InfoCard } from '@popsure/dirty-swan';

import type { CoveredItem, CoveredSection } from '../models';
import { SectionHeader } from '../SectionHeader';
import file from './icons/file.svg';
import styles from './style.module.scss';

export const WhatsCoveredSection = ({
  title,
  items,
  documentLink,
  documentName,
}: CoveredSection) => {
  return (
    <section className={`p-container ${styles.container}`}>
      <div
        className={`d-flex ai-center jc-between gap8 ${styles.titleContainer}`}
      >
        <div className={`d-flex fd-column ${styles.sectionHeaderContainer}`}>
          <SectionHeader title={title} />
        </div>
      </div>
      <div
        className={`d-flex fd-row f-wrap jc-center c-gap16 r-gap32 mt32 ${styles.tileContainer}`}
      >
        <Items items={items} />
      </div>
      <div className={`mt40 ${styles.documentLinkContainer}`}>
        {' '}
        <p className="p-p ta-center wmx9">
          For a closer look, you can read and download the{' '}
          <span>
            <img src={file} alt="policy icon" />
            <a
              className="p-a c-pointer"
              href={documentLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {documentName}
            </a>
          </span>
        </p>{' '}
      </div>
    </section>
  );
};

const Items = ({ items }: { items: CoveredItem[] }) => {
  return (
    <>
      {items.map(
        ({ title: itemTitle, icon = { src: '', alt: '' }, description }) => {
          const className = 'wmx4';
          return (
            <InfoCard
              key={itemTitle}
              className={className}
              title={itemTitle}
              topIcon={icon}
              state="static"
              dropshadow={true}
            >
              <p className="p-p tc-grey-600 ta-center">{description}</p>
            </InfoCard>
          );
        }
      )}
    </>
  );
};
