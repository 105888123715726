import styles from './style.module.scss';

export const IconCard = ({
  title,
  description,
  iconSrc,
  iconAlt,
}: {
  title: string;
  description: string;
  iconSrc: string;
  iconAlt: string;
}) => (
  <div className="p8">
    <div className="d-flex ai-start c-gap16">
      <figure className={`d-flex jc-center ai-start$ ${styles.icons}`}>
        <img src={iconSrc} alt={iconAlt} />
      </figure>
      <div>
        <h4 className={`p-h4 ${styles.title}`}>{title}</h4>
        <p className="p-p mt8 tc-grey-600">{description}</p>
      </div>
    </div>
  </div>
);
