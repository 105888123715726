type TFuncSignature = {
  (amount: number, removeTraillingDoubleZeros?: boolean): string;
};

export const spanishFormattedEuroCurrency: TFuncSignature = (
  amount: number,
  param?: boolean
) => {
  const formattedCurrencyString = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);

  return param
    ? formattedCurrencyString.replace(/,00/, '')
    : formattedCurrencyString;
};
