import '@popsure/dirty-swan/dist/index.css';
import './app.scss';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { HouseholdQuote } from './components/HouseholdQuoteEs';
import { NavigationBar } from './components/NavigationBar';
import { PageNotFound } from './components/PageNotFound';
import { RedirectToPayment } from './components/RedirectToPayment';

const App = () => {
  return (
    <div>
      <NavigationBar />
      <Router>
        <Routes>
          {/* Redirect to Stripe payment page */}
          <Route path="/redirect" element={<RedirectToPayment />} />
          {/* Spain Household quote screen */}
          <Route path="/household/:id" element={<HouseholdQuote />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
